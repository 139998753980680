//
// plyr.js
// Theme module
//

import Plyr from "plyr";

const player = new Plyr("#player");

const autoPlayer = new Plyr("#auto-player", {
	debug: true,
	volume: 0,
	autoplay: true,
	muted: true,
	loop: { active: true },
	hideControls: true,
});
